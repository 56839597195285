<template>
	<div class="modal_wrap">
		<div class="modal modal_300">
			<div class="modal_head">
				<h2>비밀번호 변경</h2>
			</div>
			<div class="mypage_tit_wrap border_b_none">
				<p>
					회원님의 비밀번호가 변경되었습니다.<br />
					쉬운 비밀번호나 다른 사이트와 비밀번호가 같을 경우, 도용되기 쉬우므로 주기적으로 비밀번호를 변경해
					주세요.
				</p>
			</div>
			<div class="btn_wrap_line">
				<button type="button" class="btn_basic btn_green_bg" @click="router.replace('/login')">확인</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
</script>
