<template>
	<div class="login_members_wrap">
		<div class="login_members">
			<div class="login_header_wrap">
				<h2>비밀번호 변경</h2>
				<p>
					쉬운 비밀번호나 다른 사이트와 비밀번호가 같을 경우, 도용되기 쉬우므로 주기적으로 비밀번호를 변경해
					주세요.
				</p>
			</div>
			<form id="change_pw_form">
				<div class="input_wrap">
					<div class="item_pw">
						<input
							type="password"
							id="pw"
							name="password"
							v-model="new_pw"
							placeholder="새 비밀번호를 입력해 주세요."
						/>
					</div>
					<div class="item_pw">
						<input
							type="password"
							id="pw"
							v-model="new_second_pw"
							placeholder="비밀번호를 다시 입력해 주세요."
						/>
					</div>
				</div>
			</form>
			<div style="margin: 5px" class="explanation">
				비밀번호는 <span class="red">8~32자의 영문+숫자+특수문자</span>조합 이어야 합니다.
			</div>
			<div class="btn_wrap">
				<button @click="modify_pw()" type="button" class="btn_basic_100 btn_green_bg">확인</button>
			</div>
			<div class="foot_servicecenter">
				<div class="call">
					<a href="">고객센터 : <span>070-8825-5004</span></a>
				</div>
				<div class="email">
					<a href="">이메일 : <span>contact@ideaconcert.com</span></a>
				</div>
			</div>
		</div>
	</div>
	<component :is="store.change_pw_clear_modal ? ModifyPwClear : null" />
</template>

<script setup>
import ajax from '@/api/api-manager';
import { ref } from 'vue';
import ModifyPwClear from './modal/ModifyPwClear.vue';
import { PW_REGI } from './Register';
import { useRouter } from 'vue-router';
import { userInfoStore } from '@/store/UserStore';
const store = userInfoStore();
const router = useRouter();
const new_pw = ref('');
const new_second_pw = ref('');
const params = document.location.href.split('?').pop().split('=').pop();

//비밀번호 수정로직
const modify_pw = () => {
	const change_pw_form = document.querySelector('#change_pw_form');
	const formdata = new FormData(change_pw_form);
	formdata.append('authKey', params);
	if (
		//유효성 검사 및 공백 검사
		new_pw.value !== '' &&
		new_second_pw.value !== '' &&
		PW_REGI.test(new_pw.value) &&
		PW_REGI.test(new_second_pw.value)
	) {
		if (new_pw.value === new_second_pw.value) {
			ajax('user/modify_pw', formdata)
				.then((response) => {
					//성공시 모달창 닫기
					store.change_pw_clear_modal = true;
				})
				.catch((error) => {
					if (error.response.data.code === 'M005') {
						//인증만료된 링크 일시
						alert('인증이 만료된 코드입니다. 처음부터 다시 시도해주세요!');
					}
				});
		} else {
			alert('비밀번호가 일치하지 않습니다!');
		}
	} else {
		alert('비밀번호 형식을 확인해주세요!');
	}
};
</script>
